
function Website (event) {

}

function sendClick(event) {
    event.preventDefault();

    let url = event.target.getAttribute('href');
    if (typeof gtag === "function") {
        gtag('event', 'click', {
            'event_category': 'outbound',
            'event_label': url,
            'transport_type': 'beacon',
            'event_callback': () => {
                document.location = url;
            }
        });
    } else {
        document.location = url;
    }
    return false;
}

function sendTouch(event) {
    let url = event.target.getAttribute('href');
    let className = event.target.getAttribute('class');
    if (typeof gtag === "function") {
        gtag('event', 'touch', {
            'url': url,
            'class': className,
        });
    }
}

function addEvents() {

    let links = document.querySelectorAll('table td > a'),
        i;
    for (i=0; i < links.length; i++) {
        links.item(i).addEventListener('click', sendClick);
        links.item(i).addEventListener('touch', sendTouch);
        links.item(i).addEventListener('tap', sendTouch);
    }
}
export { Website, addEvents };